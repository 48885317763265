import axios from "axios";
import { baseUrlOtodisLambdaAPI } from "../config/ApiUrlConfig";

class OtodisService {
  axios = axios.create({
    baseURL: baseUrlOtodisLambdaAPI,
  });

  setToken(token) {
    this.axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }

  async getDealer(params = {}) {
    try {
      params.lite_respon = true;
      return await this.axios.get("/dealer", params);
    } catch (e) {
      const { response } = e;
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error.message);
    }
  }

  async getAssignedOrder(params = {}) {
    try {
      const queries = {
        ...(params?.service_bureau_code && {
          service_bureau_code: params.service_bureau_code,
        }),
        ...(params?.dealer_code && {
          dealer_code: params.dealer_code,
        }),
        ...(params?.order_code && {
          order_code: params.order_code,
        }),
        ...(params?.date_start && {
          date_start: params.date_start,
        }),
        ...(params?.date_end && {
          date_end: params.date_end,
        }),
        ...(params?.transfer_title_status && params?.transfer_title_status !== "ALL" && {
          transfer_title_status: params.transfer_title_status,
        }),
        ...(params?.invoice_number && {
          invoice_number: params.invoice_number,
        }),
        ...(params?.vehicle_engine_number && {
          vehicle_engine_number: params.vehicle_engine_number,
        }),
        ...(params?.notice_uploaded !== undefined && {
          notice_uploaded: params.notice_uploaded,
        }),
        ...(params?.paid_notice_cost !== undefined && {
          paid_notice_cost: params.paid_notice_cost,
        }),
        ...(params?.paid_process_cost !== undefined && {
          paid_process_cost: params.paid_process_cost,
        }),
        ...(params?.flag_has_outstanding !== undefined && {
          flag_has_outstanding: params.flag_has_outstanding,
        }),
        ...(params?.stnk_uploaded !== undefined && {
          stnk_uploaded: params.stnk_uploaded,
        }),
        ...(params?.payment_approved !== undefined && {
          payment_approved: params.payment_approved,
        }),
      }

      return await this.axios.get("/order/assign-service-bureau", {
        params: {
          ...queries,
        }
      });
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  }

  async updateDocumentsOrderBBN(postData) {
    try {
      return await this.axios.put("/service-bureau/order/update/document-number", postData);
    } catch (e) {
      const { response } = e;
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error.message);
    }
  }

  async uploadInvoiceBBN(params = {}) {

    try {
      return await this.axios.post("/service-bureau/order/invoice/upload", params);
    } catch (e) {
      const { response } = e;
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error.message);
    }
  }

  async paymentInvoiceBBN(params = {}) {

    try {
      return await this.axios.post("/service-bureau/order/invoice/payment", params);
    } catch (e) {
      const { response } = e;
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error.message);
    }
  }

  async paymentNotice(params = {}) {

    try {
      return await this.axios.post("/service-bureau/order/payment/notice-cost", params);
    } catch (e) {
      const { response } = e;
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error.message);
    }
  }


  async paymentProcessBill(params = {}) {

    try {
      return await this.axios.post("/service-bureau/order/payment/process-cost", params);
    } catch (e) {
      const { response } = e;
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error.message);
    }
  }

  async paymentPkbBill(params = {}) {

    try {
      return await this.axios.post("/service-bureau/order/payment/pkb-bill", params);
    } catch (e) {
      const { response } = e;
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error.message);
    }
  }

  async UploadInvoiceServiceCost(params = {}) {

    try {
      return await this.axios.post("/service-bureau/order/invoice/upload/service-cost", params);
    } catch (e) {
      const { response } = e;
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error.message);
    }
  }

}

export const otodisService = new OtodisService();
