import Cookies from "universal-cookie";
import { PoweroffOutlined } from "@ant-design/icons";
import { withRouter } from "../../hoc/withRouter";

const ButtonLogout = (props) => {
  const cookies = new Cookies();

  const onPressed = () => {
    try {
      // Attempt to remove cookies
      // console.log("BEFORE LOGOUT", cookies.getAll());

      cookies.remove("_bbn_tn");
      cookies.remove("_bbn_un");
      cookies.remove("_bbn_ls");
      cookies.remove("_bbn_df");

      // console.log("SUDAH LOGOUT", cookies.getAll());
    } catch (error) {
      // Handle error if cookie removal fails
      console.error("Failed to remove cookies:", error);
    }

    props.history.push("/login");
  };

  return <PoweroffOutlined onClick={onPressed} style={{ padding: 0, marginLeft: 5, paddingRight: 10 }} />;
};

export default withRouter(ButtonLogout);
