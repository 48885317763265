import React, { Component } from "react";
import Cookies from "universal-cookie";
import { withRouter } from "../../hoc/withRouter";
import { Form, Select } from "antd";

const { Option } = Select;

class SelectBiro extends Component {
  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.state = {
      listServiceBureau: JSON.parse(atob(this.cookies.get("_bbn_ls"))) || {},
    };
  }

  setOptionServiceBureau = () => {
    const { listServiceBureau } = this.state;
    const listOption = Object.entries(listServiceBureau).map(([code, row]) => {
      const serviceBureauName = row.replace(/Biro |Jasa /g, "").toUpperCase();
      return (
        <Option key={code} value={code}>
          {serviceBureauName}
        </Option>
      );
    });
    return listOption;
  };

  changeServiceBureau = (value) => {
    const { listServiceBureau } = this.state;
    if (!listServiceBureau[value]) {
      this.logout();
    } else {
      this.props.history.push(`/${value}/dashboard`);
    }
  };

  logout = () => {
    const { history } = this.props;
    const cookies = new Cookies();
    cookies.remove("_bbn_tn");
    cookies.remove("_bbn_un");
    cookies.remove("_bbn_ls");
    history.push("/login");
  };

  render() {
    const { value } = this.props;
    return (
      <Form.Item>
        <Select value={value} onChange={this.changeServiceBureau} style={{ fontSize: "90%", minWidth: 250 }}>
          {this.setOptionServiceBureau()}
        </Select>
      </Form.Item>
    );
  }
}

export default withRouter(SelectBiro);
