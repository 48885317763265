import React, { Component } from "react";
import { withRouter } from "../../hoc/withRouter";
import { otodisService } from "../../services/OtodisService";
import { Form, Select } from "antd";

const { Option } = Select;

class SelectDealer extends Component {
  initState = {
    dataOption: [],
  };
  state = { ...this.initState };

  componentDidMount = async () => {
    try {
      const dataDealer = await otodisService.getDealer();

      let listOption = dataDealer.data.data.map((d) => {
        return { dealer_code: d.dealer_code, dealer_name: d.dealer_name };
      });

      this.setState({
        dataOption: listOption,
      });
    } catch (error) {
      this.setState({
        dataOption: [],
      });
    }
  };

  render() {
    return (
      <Form.Item label="Dealer" name="dealer" className="col-lg-12">
        <Select style={{ fontSize: "85%" }} placeholder="Select Dealer" onChange={this.props.onChangeValue}>
          {this.state.dataOption.map((d, i) => {
            return (
              <Option key={d.dealer_code} value={d.dealer_code}>
                {d.dealer_name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  }
}

export default withRouter(SelectDealer);
