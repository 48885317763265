import React, { Component } from "react";
import Cookies from "universal-cookie";
import Spreadsheet from "react-spreadsheet";

import withAuth from "../hoc/withAuth";
import MainLayout from "../components/layouts/MainLayout";
import SelectDealer from "../components/selects/SelectDealer";

import { otodisService } from "../services/OtodisService";

import { Form, Input, Button, Modal, Row, Col } from "antd";
import { UploadOutlined, ReloadOutlined } from "@ant-design/icons";

class ServiceBureauUploadInvoice extends Component {
  token = new Cookies().get("_bbn_tn");
  columnLabels = ["Order Code", "Konsumen", "Nomor Polisi", "Nomor BPKB", "Nomor STNK", "Nomor Notice"];

  constructor(props) {
    super(props);

    const { match } = this.props;
    const code_svb = match.params.code_svb;

    this.initState = {
      codeSVB: code_svb,
      invoiceNumber: "",
      loadingButton: false,
      dealer_code: "",
      dataSheet: [
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
      ],
    };

    this.state = { ...this.initState };
  }

  componentDidMount = () => {
    this.onResetSheet();
  };

  changeFilterDealer = (v) => {
    this.setState({
      dealer_code: v,
    });
  };

  changeInvoiceNumber = (e) => {
    this.setState({
      invoiceNumber: e.target.value,
    });
  };

  onSheetChange = (newData) => {
    // if (newData !== this.state.dataSheet)
    this.setState({ dataSheet: newData });
  };

  onResetSheet = () => {
    this.setState({
      dataSheet: [
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
      ],
    });
  };

  onClickUploadData = async () => {
    const { codeSVB, invoiceNumber, dataSheet, dealer_code } = this.state;

    if (!invoiceNumber) {
      Modal.error({
        title: "Validation Error",
        content: "Invoice Number Not Valid",
      });
      return false;
    }

    const dataCleaned = dataSheet
      .map((row) => {
        if (row[0]?.value !== "" && row[0]?.value !== undefined) {
          return {
            order_code: row[0].value,
            document_customer_name: row[1].value,
            vehicle_license_plate: row[2].value,
            vehicle_certificate_of_ownership_number: row[3].value,
            vehicle_registration_certificate_number: row[4].value,
            vehicle_notice_number: row[5].value,
          };
        } else {
          return null;
        }
      })
      .filter((data) => data !== null);

    if (dataCleaned.length > 0) {
      try {
        this.setState({
          loadingButton: true,
        });
        const params = {
          service_bureau_code: codeSVB,
          invoice_number: invoiceNumber,
          order_list: dataCleaned,
          dealer_code: dealer_code,
        };

        otodisService.setToken(this.token);
        const uploadInvoice = await otodisService.uploadInvoiceBBN(params);
        // console.log(JSON.stringify(params));
        // console.log(uploadInvoice.data.success.data);
        Modal.info({
          title: "Data Processed",
          width: 800,
          content: (
            <div className="table-responsive" style={{ height: 200 }}>
              <table className="table table-bordered table-sm" style={{ fontSize: "83%" }}>
                <tr>
                  <th>Order Code</th>
                  <th>Nama Pemesan</th>
                  <th>Nomor Mesin</th>
                  <th>Nilai BBN</th>
                  <th>Keterangan</th>
                </tr>
                {uploadInvoice.data.error.data.map((row) => {
                  return (
                    <tr key={row.orderCode}>
                      <td>{row.order_code}</td>
                      <td>{row.document_customer_name}</td>
                      <td>-</td>
                      <td>{row.total_cost}</td>
                      <td className="text-danger">FAILED - {row.description}</td>
                    </tr>
                  );
                })}
                {uploadInvoice.data.success.data.map((row) => {
                  return (
                    <tr key={row.orderCode}>
                      <td>{row.order_code}</td>
                      <td>{row.document_customer_name}</td>
                      <td>{row.vehicle_engine_number}</td>
                      <td>{row.total_cost}</td>
                      <td className="text-success">SUCCESS</td>
                    </tr>
                  );
                })}
              </table>
            </div>
          ),
        });
        this.onResetSheet();
      } catch (error) {
        this.setState({
          loadingButton: false,
        });
        Modal.error({
          title: "Process Error",
          content: error.message,
        });
      }
    } else {
      Modal.error({
        title: "Validation Error",
        content: "Data Table Kosong",
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <MainLayout defaultOpenKeys={["m-invoice"]} defaultSelectedKeys={["m-invoice-upload"]}>
          <div style={{ padding: 10, margin: 10, background: "#FFF" }}>
            <Form name="form-input" layout="vertical">
              <Row>
                <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 3 }} lg={{ span: 4 }} md={{ span: 6 }} sm={{ span: 12 }}>
                  <SelectDealer value={this.state.dealer_code} onChangeValue={this.changeFilterDealer} />
                </Col>
                <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 3 }} lg={{ span: 4 }} md={{ span: 6 }} sm={{ span: 12 }}>
                  <Form.Item label="Nomor Tagihan" name="invoice_number" onChange={this.changeInvoiceNumber}>
                    <Input placeholder="Nomor Tagihan" />
                  </Form.Item>
                </Col>
                <Col style={{ paddingLeft: 5, paddingRight: 5 }}>
                  <Form.Item label="&nbsp;">
                    <Button onClick={this.onClickUploadData} type="primary" icon={<UploadOutlined />} title="Upload Tagihan" style={{ background: "#1784b8", borderColor: "#1784b8" }} loading={this.state.loadingButton}>
                      Upload Data
                    </Button>
                  </Form.Item>
                </Col>
                <Col style={{ paddingLeft: 5, paddingRight: 5 }}>
                  <Form.Item label="&nbsp;">
                    <Button onClick={this.onResetSheet} type="primary" icon={<ReloadOutlined />} title="Upload Tagihan" style={{ background: "#ca2e2e", borderColor: "#ca2e2e" }} loading={this.state.loadingButton}>
                      Refresh Table
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col xl={{ span: 24 }}>
                <Spreadsheet data={this.state.dataSheet} columnLabels={this.columnLabels} onChange={this.onSheetChange} />
              </Col>
            </Row>
          </div>
        </MainLayout>
      </React.Fragment>
    );
  }
}

export default withAuth(ServiceBureauUploadInvoice);
