import React, { Component } from "react";
import Cookies from "universal-cookie";
import moment from "moment";

import withAuth from "../hoc/withAuth";
import MainLayout from "../components/layouts/MainLayout";
import SelectDealer from "../components/selects/SelectDealer";

import { config } from "../config/GeneralConfig";
import { otodisService } from "../services/OtodisService";

import { Form, DatePicker, Button, Input, Modal, Row, Col } from "antd";
import { SearchOutlined, ExportOutlined } from "@ant-design/icons";
import TransferBanyarNotice from "../components/table/TransferBanyarNotice";
import SelectStatusNoticePayment from "../components/selects/SelectStatusNoticePayment";

class TaxSamsatPaymentRetry extends Component {
  token = new Cookies().get("_bbn_tn");
  constructor(props) {
    super(props);

    const { match } = this.props;
    const code_svb = match.params.code_svb;

    this.initState = {
      codeSVB: code_svb,
      filterDealer: "",
      filterStatusNotice: "UPLOADED",
      filterDateOrder: "",
      filterInvoiceNumber: "",

      filterStockEngine: "",
      dataLeftStock: [],
      dataRightStock: [],
      dataRightStockTemp: [],
      dataSelectedStock: [],
      rowSelectedStock: {},

      inputEmail: "",
      inputDateGenerate: moment(new Date()).format("YYYY-MM-DD"),
      totalAmountSelected: 0,
      loadingButton: false,
    };

    this.state = { ...this.initState };
  }

  disabledDate = (current) => {
    // Can not select days before today
    let oneDay = 1000 * 60 * 60 * 24;
    let dateNow = Date.now();
    let limitDate = dateNow - oneDay;
    return current && current.valueOf() < limitDate;
  };

  changeFilterDealer = (v) => {
    this.setState({
      dataLeftStock: [],
      filterDealer: v,
    });
  };

  changeFilterStatusNotice = (v) => {
    this.setState({
      dataLeftStock: [],
      filterStatusNotice: v,
    });
  };

  changeFilterDateOrder = (date, dateString) => {
    this.setState({
      dataLeftStock: [],
      filterDateOrder: dateString,
    });
  };

  changeFilterInvoiceNumber = (v) => {
    this.setState({
      dataLeftStock: [],
      filterInvoiceNumber: v.target.value,
    });
  };

  changeInputDateGenerate = (date, dateString) => {
    this.setState({
      inputDateGenerate: dateString,
    });
  };

  changeInputEmail = (v) => {
    this.setState({
      inputEmail: v.target.value,
    });
  };

  onClickFilter = async () => {
    // await this.fetchDataOrder();
    await this.fetchStockData();
  };

  fetchStockData = async () => {
    const filter = this.state;
    if (!filter.filterDealer) {
      Modal.error({
        title: "Validation Error",
        content: "Dealer is Required",
      });
      return false;
    }
    if (!filter.filterDateOrder) {
      Modal.error({
        title: "Validation Error",
        content: "Tanggal Order is Required",
      });
      return false;
    }

    // console.log("dataLeftStock", this.state.dataLeftStock);
    // console.log("dataRightStock", this.state.dataRightStock);
    // console.log("dataRightStockTemp", this.state.dataRightStockTemp);
    // console.log("dataSelectedStock", this.state.dataSelectedStock);
    // console.log("rowSelectedStock", this.state.rowSelectedStock);

    this.setState({
      loadingButton: true,
      dataLeftStock: [],
      dataRightStock: [],
      dataRightStockTemp: [],
      dataSelectedStock: [],
      rowSelectedStock: [],
    });

    const dateStart = moment(filter.filterDateOrder).clone().startOf("month").format("YYYY-MM-DD");
    const dateEnd = moment(filter.filterDateOrder).clone().endOf("month").format("YYYY-MM-DD");

    const params = {
      dealer_code: filter.filterDealer,
      date_start: dateStart,
      date_end: dateEnd,
      invoice_number: filter.filterInvoiceNumber,
      service_bureau_code: this.state.codeSVB,
      transfer_title_status: this.state.filterStatusNotice,
      notice_uploaded: true,
      paid_notice_cost: true,
      flag_has_outstanding: true,
      stnk_uploaded: true,
    };

    otodisService.setToken(this.token);
    otodisService
      .getAssignedOrder(params)
      .then(async (value) => {
        const dataRightStockTemp = this.state.dataRightStockTemp;
        const mockDataTemp = [...dataRightStockTemp];

        for (const item of value.data.success.data) {
          const isExist = mockDataTemp.some((c) => c.key === item.order_code);
          if (!isExist) {
            // console.log(item);
            const data = {
              key: item.order_code,
              title: item.vehicle_engine_number,
              vehicle_engine_number: item.vehicle_engine_number,
              description: `${item.vehicle_engine_number} \n ${item.customer_doc_name}`,
              bbn_status: item.transfer_title_status,
              master_notice_cost: item.master_notice_cost,
              samsat_notice_cost: item?.vehicle_notice_data?.jumlah,
              samsat_pkb_cost: item?.vehicle_notice_data?.pkb,
              total_payment: Number(item?.vehicle_notice_data?.jumlah) - Number(item?.vehicle_notice_data?.pkb),
              vehicle_notice_image: item?.vehicle_notice_image,
              upload_bbn_date: item?.transfer_title_date,
              upload_notice_date: item?.vehicle_notice_entry_date,
            };
            mockDataTemp.push(data);
          }
        }

        this.setState({
          dataLeftStock: mockDataTemp,
          loadingButton: false,
        });
      })
      .catch(async (reason) => {
        this.setState({
          dataLeftStock: [],
          loadingButton: false,
        });

        Modal.error({
          title: "Error",
          content: "Get Data Failed, " + reason.message,
        });
      });
  };

  onSelectStock = (nextTargetKeys) => {
    const { dataLeftStock } = this.state;
    const dataRightStockTemp = dataLeftStock.filter((item) => nextTargetKeys.includes(item.key));

    this.setState({ dataRightStock: nextTargetKeys, dataRightStockTemp: dataRightStockTemp });
    this.calculateTotalInvoicePrice(nextTargetKeys, dataRightStockTemp);
  };

  calculateTotalInvoicePrice = (dataRightStock, dataRightStockTemp) => {
    let totalInvoicePrice = 0;

    for (const r of dataRightStockTemp) {
      if (dataRightStock.includes(r.key)) {
        totalInvoicePrice += r.total_payment;
      }
    }

    console.log("totalInvoicePrice", totalInvoicePrice);

    return this.setState({ totalAmountSelected: totalInvoicePrice });
  };

  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  onClickGenerate = async () => {
    let { filterDealer, codeSVB, inputEmail, inputDateGenerate, dataRightStock, totalAmountSelected } = this.state;
    if (!inputDateGenerate) {
      Modal.error({
        title: "Validation Error",
        content: "Tanggal Generate is Required",
      });
      return false;
    }

    if (!inputEmail) {
      Modal.error({
        title: "Validation Error",
        content: "Email is Required",
      });
      return false;
    } else if (!this.validateEmail(inputEmail)) {
      Modal.error({
        title: "Validation Error",
        content: "Email is not Valid",
      });
      return false;
    }

    if (dataRightStock.length < 1) {
      Modal.error({
        title: "Validation Error",
        content: "Tidak Ada Data yang Dipilih",
      });
      return false;
    }

    if (totalAmountSelected > config.limit_pay_invoice) {
      Modal.error({
        title: "Validation Error",
        content: "Total Amount melebihi limit " + config.limit_pay_invoice,
      });
      return false;
    }

    try {
      this.setState({
        loadingButton: true,
      });

      otodisService.setToken(this.token);
      await otodisService.paymentNotice({
        dealer_code: filterDealer,
        service_bureau_code: codeSVB,
        date_transfer: inputDateGenerate,
        email: inputEmail,
        order_list: dataRightStock,
      });

      // let newSourceData = dataLeftStock.filter((e) => !dataRightStock.includes(e.key));

      this.setState({
        dataLeftStock: [],
        dataRightStock: [],
        dataRightStockTemp: [],
        dataSelectedStock: [],
        rowSelectedStock: [],
        totalAmountSelected: 0,
        loadingButton: false,
      });
      Modal.success({
        content: "Payment Generated, Please check Email",
      });
    } catch (error) {
      this.setState({
        loadingButton: false,
      });
      Modal.error({
        title: "Process Error",
        content: error.message,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <MainLayout defaultOpenKeys={["m-payment"]} defaultSelectedKeys={["m-payment-notice-retry"]}>
          <div style={{ padding: 10, margin: 10, background: "#FFF" }}>
            <Row>
              <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                <Form name="form-filter" layout="vertical">
                  <Row>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }}>
                      <SelectDealer value={this.state.filterDealer} onChangeValue={this.changeFilterDealer} />
                    </Col>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }}>
                      <SelectStatusNoticePayment value={this.state.filterStatusNotice} onChangeValue={this.changeFilterStatusNotice} />
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }}>
                      <Form.Item label="Tanggal Order" name="date">
                        <DatePicker onChange={this.changeFilterDateOrder} picker="month" />
                      </Form.Item>
                    </Col>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }}>
                      <Form.Item label="Nomor Tagihan" name="invoice_number">
                        <Input onChange={this.changeFilterInvoiceNumber} placeholder="Nomor Tagihan" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }}>
                      <Button onClick={this.onClickFilter} type="primary" icon={<SearchOutlined />} title="Filter" style={{ background: "#1784b8", borderColor: "#1784b8", minWidth: 120 }} loading={this.state.loadingButton}>
                        Filter
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <br />
              </Col>
              <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                <Form name="form-input" layout="vertical">
                  <Row>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }}>
                      <Form.Item label="Tanggal Generate" name="date">
                        <DatePicker onChange={this.changeInputDateGenerate} picker="date" disabledDate={this.disabledDate} defaultValue={moment()} />
                      </Form.Item>
                    </Col>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }}>
                      <Form.Item label="Email" name="email" rules={[{ type: "email" }]}>
                        <Input onChange={this.changeInputEmail} placeholder="Email Penerima" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }}>
                      <Form.Item label="Total Amount">
                        <Input
                          value={this.numberWithCommas(this.state.totalAmountSelected)}
                          className="text-right text-info"
                          // style={{ background: "none", border: "none" }}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }}>
                      <Button onClick={this.onClickGenerate} type="primary" icon={<ExportOutlined />} title="Genarate Email" style={{ background: "#1784b8", borderColor: "#1784b8" }} loading={this.state.loadingButton}>
                        Generate Payment to Email
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 3 }} lg={{ span: 4 }} md={{ span: 6 }} sm={{ span: 24 }}>
                <br />

                <TransferBanyarNotice
                  dataSource={this.state.dataLeftStock}
                  targetKeys={this.state.dataRightStock}
                  disabled={false}
                  showSearch={true}
                  onChange={this.onSelectStock}
                  showModalFormVehicle={this.showModalFormVehicle}
                  filterOption={(inputValue, option) => option?.title.indexOf(inputValue) > -1 || option?.description.indexOf(inputValue) > -1}
                />
              </Col>
            </Row>
          </div>
        </MainLayout>
      </React.Fragment>
    );
  }
}

export default withAuth(TaxSamsatPaymentRetry);
