import React from "react";
import Cookies from "universal-cookie";
import { Navigate } from "react-router-dom";
import { withRouter } from "./withRouter";

export default function withAuth(Component) {
  class InnerHoc extends React.Component {
    render() {
      const cookies = new Cookies();
      const token = cookies.get("_bbn_tn");
      const username = cookies.get("_bbn_un");
      // console.log(username);

      if (token && username !== "cm9yYQ==") {
        return <Component {...this.props} />;
      } else {
        console.log("auth : why u came here");
        return <Navigate to="/login" />;
      }
    }
  }

  return withRouter(InnerHoc);
}
