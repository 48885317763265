import React, { Component } from "react";
import { withRouter } from "../../hoc/withRouter";
import { config } from "../../config/GeneralConfig";
import { Form, Select } from "antd";

const { Option } = Select;

class SelectStatusBayar extends Component {
  render() {
    const { status_bayar } = config;
    return (
      <Form.Item label="Status Bayar">
        <Select style={{ fontSize: "85%" }} placeholder="Select Status" defaultValue={this.props.value} onChange={this.props.onChangeValue}>
          {Object.keys(status_bayar).map((s) => {
            return (
              <Option key={s} value={s}>
                {status_bayar[s]}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  }
}

export default withRouter(SelectStatusBayar);
