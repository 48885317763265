import React, { Component } from "react";

import withAuth from "../hoc/withAuth";
import MainLayout from "../components/layouts/MainLayout";
import QRScanner from "../components/misc/QRScanner";

import { uploadDocumentService } from "../services/UploadDocumentService";

import { Form, Button, Input, Select, Upload, Modal, Row, Col } from "antd";
import { FileDoneOutlined, UploadOutlined } from "@ant-design/icons";

// import QrReader from "react-qr-reader";

const { Option } = Select;

class DocumentsUpload extends Component {
  constructor(props) {
    super(props);

    const { match } = this.props;
    const code_svb = match.params.code_svb;

    this.initState = {
      codeSVB: code_svb,
      inputDocumentType: "stnk",
      inputDocumentNumber: "",
      inputOrderDate: "",
      inputVehicleEngine: "",
      loadingButton: false,
      inputImage: [],
      validImage: false,
      hiddenImage: "block",
      cameraPermissionDenied: false,
    };

    this.state = { ...this.initState };
    this.formInput = React.createRef();
  }

  changeInputDocumentType = (v) => {
    if (v === "stnk") {
      this.setState({
        hiddenImage: "block",
      });
    } else {
      this.setState({
        hiddenImage: "none",
      });
    }
    this.setState({
      inputDocumentType: v,
    });
  };

  changeInputDocumentNumber = (v) => {
    this.setState({
      inputDocumentNumber: v.target.value,
    });
  };

  changeInputVehicleEngine = (v) => {
    this.setState({
      inputVehicleEngine: v.target.value,
    });
  };

  beforeUploadFile = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      Modal.error({
        title: "Validation Error",
        content: "Format File Bukan JPG/PNG",
      });
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      Modal.error({
        title: "Validation Error",
        content: "File Size harus kurang dari 2MB",
      });
    }
    if (isJpgOrPng && isLt2M) {
      this.setState({
        inputImage: file,
        validImage: true,
      });
    } else {
      this.setState({
        validImage: false,
      });
    }
    return false;
  };

  onClickSave = async () => {
    const { codeSVB, inputDocumentType, inputDocumentNumber, inputVehicleEngine, inputImage, validImage } = this.state;

    if (!inputDocumentType) {
      Modal.error({
        title: "Validation Error",
        content: "Tipe Dokumen tidak Valid",
      });
      return false;
    }
    if (!inputDocumentNumber) {
      Modal.error({
        title: "Validation Error",
        content: "No Dokumen tidak Valid",
      });
      return false;
    }
    if (!inputVehicleEngine) {
      Modal.error({
        title: "Validation Error",
        content: "No Mesin tidak Valid",
      });
      return false;
    }
    if (inputDocumentType === "stnk" && !validImage) {
      Modal.error({
        title: "Validation Error",
        content: "Gambar STNK tidak valid",
      });
      return false;
    }
    this.setState({
      loadingButton: true,
    });
    const formData = new FormData();
    formData.append("service_bureau_code", codeSVB);
    formData.append("vehicle_engine_number", inputVehicleEngine);
    formData.append("document_type", inputDocumentType);
    formData.append("document_number", inputDocumentNumber);

    if (inputDocumentType === "stnk" && validImage) {
      formData.append("document_image", inputImage);
    }

    try {
      let upload = await uploadDocumentService.upload(formData);
      Modal.success({
        content: upload.data.success.message,
      });
      this.formInput.current.resetFields();
      this.setState({
        inputImage: [],
        validImage: false,
        hiddenImage: "none",
        loadingButton: false,
      });
    } catch (error) {
      this.setState({
        loadingButton: false,
      });
      Modal.error({
        title: "Process Error",
        content: error.message,
      });
    }
  };

  handleScan = (data) => {
    console.log("HASIL SCAN", data?.text);

    if (data && this.state.inputDocumentNumber === "") {
      this.setState({
        inputDocumentType: "bpkb",
        inputDocumentNumber: data.text,
      });
    }
  };

  handleError = (err) => {
    if (err && err.name === "NotAllowedError") {
      // Tangani ketika akses kamera tidak diizinkan
      this.setState({ cameraPermissionDenied: true });
    } else {
      console.error(err);
    }
  };

  render() {
    return (
      <React.Fragment>
        <MainLayout defaultOpenKeys={["m-document"]} defaultSelectedKeys={["m-document-upload"]}>
          <div style={{ padding: 10, margin: 10, background: "#FFF" }}>
            <Form ref={this.formInput} name="form-filter" layout="vertical">
              <Row>
                <Col xs={{ span: 24 }} xl={{ span: 16 }} lg={{ span: 20 }} md={{ span: 20 }} sm={{ span: 24 }}>
                  <Row>
                    <Col style={{ paddingLeft: 5, paddingRight: 5, float: "right" }} xs={{ span: 24 }} xl={{ span: 8 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                      <Form.Item label="Scan Barcode">
                        <div style={{ border: "1px solid #DDD", minWidth: 200, minHeight: 200 }}>
                          {/* Tampilkan QRScanner hanya jika izin kamera diberikan */}
                          {this.state.cameraPermissionDenied === false && <QRScanner onError={this.handleError} onScan={this.handleScan} />}
                          {/* Tampilkan pesan jika izin kamera tidak diberikan */}
                          {this.state.cameraPermissionDenied === true && <div>Kamera tidak diizinkan. Silakan izinkan akses kamera untuk menggunakan fitur ini.</div>}
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }}>
                      <Form.Item label="Tipe Dokumen">
                        <Select placeholder="Tipe Dokumen" style={{ fontSize: "85%", minWidth: "200px" }} value={this.state.inputDocumentType} onChange={this.changeInputDocumentType}>
                          <Option value="pelat">PELAT</Option>
                          {/* <Option value="notice">NOTICE</Option> */}
                          <Option value="stnk">STNK</Option>
                          {/* <Option value="bpkb">BPKB</Option> */}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }}>
                      <Form.Item label="Nomor Dokumen">
                        <Input value={this.state.inputDocumentNumber} onChange={this.changeInputDocumentNumber} placeholder="Nomor Dokumen"></Input>
                      </Form.Item>
                    </Col>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }}>
                      <Form.Item label="Nomor Mesin">
                        <Input value={this.state.inputVehicleEngine} onChange={this.changeInputVehicleEngine} placeholder="Nomor Mesin" />
                      </Form.Item>
                    </Col>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }}>
                      <Form.Item label="File Document" style={{ display: this.state.hiddenImage }}>
                        <Upload maxCount={1} listType="picture" beforeUpload={this.beforeUploadFile}>
                          <Button icon={<UploadOutlined />} loading={this.state.loadingButton}>
                            Select File
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }}>
                      <Form.Item>
                        <Button onClick={this.onClickSave} type="primary" icon={<FileDoneOutlined />} title="Simpan" className="my-2 offset-lg-3" style={{ background: "#1784b8", borderColor: "#1784b8" }} loading={this.state.loadingButton}>
                          Simpan
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        </MainLayout>
      </React.Fragment>
    );
  }
}

export default withAuth(DocumentsUpload);
