import React, { Component } from "react";
import QrReader from "react-qr-scanner";
import { withRouter } from "../../hoc/withRouter";

class QRScanner extends Component {
  render() {
    return (
      <div>
        <QrReader delay={3000} onError={this.props.onError} onScan={this.props.onScan} style={{ width: "100%" }} />
      </div>
    );
  }
}

export default withRouter(QRScanner);
