import React from "react";
import withAuth from "../hoc/withAuth";
import MainLayout from "../components/layouts/MainLayout";

class Dashboard extends React.Component {
  render() {
    return (
      <React.Fragment>
        <MainLayout defaultSelectedKeys={["m-dashboard"]}>
          <div style={{ padding: 10, margin: 10, background: "#FFF", height: "80vh" }}>Welcome</div>
        </MainLayout>
      </React.Fragment>
    );
  }
}

export default withAuth(Dashboard);
