import React, { Component } from "react";

import withAuth from "../hoc/withAuth";
import MainLayout from "../components/layouts/MainLayout";

import { uploadDocumentService } from "../services/UploadDocumentService";

import { Form, Button, Input, Upload, Modal, Row, Col, Space, Spin, Image } from "antd";
import { RightCircleOutlined, UploadOutlined } from "@ant-design/icons";

class DocumentsUploadBpkb extends Component {
  constructor(props) {
    super(props);

    const { match } = this.props;
    const code_svb = match.params.code_svb;

    this.initState = {
      codeSVB: code_svb,
      inputVehicleEngine: "",
      typeNotice: "",
      loadingButton: false,

      inputImage: [],
      validImage: false,
      inputImagePreview: "",

      modalMismatchVisible: false,
      modalInputDesc: "",

      modalConfirmVisible: false,
      editMode: false,
      editButtonText: "Edit",

      resultObject: {},
      resultCustomerName: "",
      resultEngineNumber: "",
      resultChassisNumber: "",
      resultBpkbNumber: "",
    };

    this.state = { ...this.initState };
    this.formInput = React.createRef();
  }

  changeInputVehicleEngine = (v) => {
    this.setState({
      inputVehicleEngine: v.target.value,
    });
  };

  changeTypeNotice = (v) => {
    this.setState({
      typeNotice: v,
    });
  };

  handleFieldChange = (field, value) => {
    // console.log("Input =", this.state.resultBbnkb);
    // console.log("Result API =", this.state.resultObject?.bbnkb);
    this.setState({ [field]: value });

    // console.log("Input =", this.state.resultBbnkb);
    // console.log("Result API =", this.state.resultObject?.bbnkb);
  };

  beforeUploadFile = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      Modal.error({
        title: "Validation Error",
        content: "Format File Bukan JPG/PNG",
      });
    }
    if (isJpgOrPng) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.setState({
          inputImagePreview: event.target.result,
          inputImage: file,
          validImage: true,
        });
      };

      reader.readAsDataURL(file);
    } else {
      this.setState({
        validImage: false,
      });
    }
    return false;
  };

  onExtractDokumen = async () => {
    try {
      const { inputImage, validImage } = this.state;

      this.setState({
        loadingButton: true,
      });

      const formData = new FormData();

      if (validImage) {
        formData.append("document", inputImage);
      }

      let extractedData = await uploadDocumentService.uploadBpkb(formData);
      this.setState({
        loadingButton: false,
        resultObject: extractedData?.data?.data,
        resultCustomerName: extractedData?.data?.data?.customer_name,
        resultEngineNumber: extractedData?.data?.data?.engine_number,
        resultChassisNumber: extractedData?.data?.data?.chassis_number,
        resultBpkbNumber: extractedData?.data?.data?.bpkb_number,
      });

      Modal.success({
        title: "Success Upload",
        content: "BPKB Berhasil Diupload",
      });
    } catch (error) {
      this.setState({
        loadingButton: false,
      });

      if (error.message) {
        Modal.error({
          title: "Process Error",
          content: error.message,
        });
      } else {
        Modal.error({
          title: "Process Error",
          content: "Error Timeout",
        });
      }
    }
  };

  onMismatchData = () => {
    try {
      const { typeNotice, inputVehicleEngine, validImage, resultObject } = this.state;

      if (Object.keys(resultObject).length < 1) {
        throw new Error("Belum mengextract data");
      }

      if (!typeNotice) {
        throw new Error("Tipe Notice tidak valid");
      }
      if (!inputVehicleEngine) {
        throw new Error("Nomor Mesin tidak valid");
      }
      if (!validImage) {
        throw new Error("File Dokumen tidak valid");
      }

      this.setState({ modalMismatchVisible: true });
    } catch (error) {
      this.setState({
        loadingButton: false,
      });
      Modal.error({
        title: "Process Error",
        content: error.message,
      });
    }
  };

  handleCancel = () => {
    this.setState({ modalMismatchVisible: false, modalConfirmVisible: false });
  };

  render() {
    return (
      <React.Fragment>
        <MainLayout defaultOpenKeys={["m-document"]} defaultSelectedKeys={["m-upload-bpkb"]}>
          <div style={{ padding: 10, margin: 10, background: "#FFF" }}>
            <Form ref={this.formInput} name="form-filter" layout="vertical">
              <Row>
                <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 6 }} lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 24 }}>
                  <Space.Compact>
                    <Form.Item style={{ display: this.state.hiddenImage }} label="Upload File">
                      <Upload maxCount={1} showUploadList={false} beforeUpload={this.beforeUploadFile}>
                        <Button icon={<UploadOutlined />} loading={this.state.loadingButton} style={{ width: "100%" }}>
                          Select File
                        </Button>
                      </Upload>
                      {/* </Form.Item>
                        <Form.Item> */}
                      <Button onClick={this.onExtractDokumen} type="primary" icon={<RightCircleOutlined />} title="Simpan" style={{ background: "#1784b8", borderColor: "#1784b8" }} loading={this.state.loadingButton}>
                        Upload BPKB
                      </Button>
                    </Form.Item>
                  </Space.Compact>
                  {this.state.inputImagePreview && (
                    <Form.Item>
                      <Image width={"inherit"} src={this.state.inputImagePreview} />
                    </Form.Item>
                  )}
                </Col>
              </Row>
              {/* <Row>
                <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 6 }} lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 24 }}>
                  <Form.Item label="Nama Konsumen">
                    <Input value={this.state.resultCustomerName} onChange={(event) => this.handleFieldChange("resultCustomerName", event.target.value)} disabled />
                  </Form.Item>
                </Col>
                <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 6 }} lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 24 }}>
                  <Form.Item label="No BPKB">
                    <Input value={this.state.resultBpkbNumber} onChange={(event) => this.handleFieldChange("resultBpkbNumber", event.target.value)} disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 6 }} lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 24 }}>
                  <Form.Item label="Nomor Mesin">
                    <Input value={this.state.resultEngineNumber} onChange={(event) => this.handleFieldChange("resultEngineNumber", event.target.value)} disabled />
                  </Form.Item>
                </Col>
                <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 6 }} lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 24 }}>
                  <Form.Item label="Nomor Rangka">
                    <Input value={this.state.resultChassisNumber} onChange={(event) => this.handleFieldChange("resultChassisNumber", event.target.value)} disabled />
                  </Form.Item>
                </Col>
              </Row> */}
            </Form>
          </div>

          <Modal visible={this.state.loadingButton} title="Loading" maskClosable={false} footer={null} closable={false}>
            <div style={{ textAlign: "center" }}>
              <Spin size="large" />
              <p style={{ marginTop: 16 }}>Loading...</p>
            </div>
          </Modal>

          <Modal
            title="Konfirmasi"
            maskClosable={false}
            closable={false}
            visible={this.state.modalConfirmVisible}
            onCancel={this.handleCancel}
            footer={[
              <Button key="ok" type="primary" onClick={this.onConfirmEdit} loading={this.state.loadingButton}>
                Ya, saya yakin
              </Button>,
              <Button key="cancel" onClick={this.handleCancel} loading={this.state.loadingButton}>
                Batal
              </Button>,
            ]}
          >
            <p>Mengedit data Hasil Extract akan menjadikan data perlu di review terlebih dahulu sebelum melakukan pembayaran. Apakah Anda yakin ingin melanjutkan?</p>
          </Modal>
        </MainLayout>
      </React.Fragment>
    );
  }
}

export default withAuth(DocumentsUploadBpkb);
