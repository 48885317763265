import React, { Component } from "react";
import Cookies from "universal-cookie";
import { withRouter } from "../hoc/withRouter";

import { Button, Col, Form, Input, Modal, Row } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { config } from "../config/GeneralConfig";
import { authServices } from "../services/AuthService";

class Login extends Component {
  cookies = new Cookies();
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loading: false,
    };
  }

  handleFieldChange = (field, value) => {
    this.setState({ [field]: value });
  };

  onLogin = async () => {
    try {
      this.setState({
        loading: true,
      });

      await authServices
        .login({
          username: this.state.username,
          password: this.state.password,
        })
        .then(async (dataLogin) => {
          this.setState({
            loading: false,
          });

          const defaultSVB = dataLogin.data.data.default_project["project_code"];
          let ls = {};
          for (const r of dataLogin.data.data.access_project) {
            ls[r.project_code] = r.project_name;
          }

          if (!defaultSVB) {
            Modal.error({
              title: "Error",
              content: "Something Went Wrong, Default Code Not Defined ",
            });
            return false;
          }

          this.cookies.set("_bbn_tn", dataLogin.data.data.token);
          this.cookies.set("_bbn_un", btoa(dataLogin.data.data.username));
          this.cookies.set("_bbn_ls", btoa(JSON.stringify(ls)));
          this.cookies.set("_bbn_df", defaultSVB);

          authServices.setToken(dataLogin.data.data.token);
          if (this.cookies.get("_bbn_tn") !== undefined && this.cookies.get("_bbn_tn") !== "") {
            // console.log(this.cookies.get("_bbn_tn"));
            await this.props.history.push(`/${defaultSVB}/dashboard`);
          }
        });
    } catch (error) {
      Modal.error({
        title: "Proses Gagal",
        content: "Server Menolak Inputan Anda :" + error.message,
      });

      this.setState({
        loading: false,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <Row justify="center" align="middle">
          <Col lg={6}>
            <div style={{ height: "100vh", marginTop: "25vh" }}>
              <h1>Portal Biro Jasa</h1>
              <Form name="form-login" layout="vertical">
                <Form.Item
                  label="Username"
                  // name="username"
                >
                  <Input onChange={(event) => this.handleFieldChange("username", event.target.value)} value={this.state.username} placeholder="Username" prefix={<UserOutlined />} />
                </Form.Item>
                <Form.Item
                  label="Password"
                  // name="password"
                >
                  <Input.Password onChange={(event) => this.handleFieldChange("password", event.target.value)} placeholder="Password" prefix={<LockOutlined />} value={this.state.password} />
                </Form.Item>
                <Form.Item>
                  <Button onClick={this.onLogin} type="primary" className="px-4" loading={this.state.loading}>
                    Login
                  </Button>
                </Form.Item>
              </Form>
              <center>
                Portal Biro Jasa ©2021
                <br />
                version-{config.version}
              </center>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default withRouter(Login);
