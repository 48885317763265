import React, { Component } from "react";
import Cookies from "universal-cookie";
import Spreadsheet from "react-spreadsheet";

import withAuth from "../hoc/withAuth";
import MainLayout from "../components/layouts/MainLayout";
import SelectDealer from "../components/selects/SelectDealer";

import { otodisService } from "../services/OtodisService";

import { Form, Button, Modal, Row, Col } from "antd";
import { UploadOutlined, ReloadOutlined } from "@ant-design/icons";

class DocumentsUpdateNumber extends Component {
  token = new Cookies().get("_bbn_tn");
  columnLabels = ["Nomor Mesin", "Nomor Polisi", "Nomor BPKB", "Nomor STNK", "Nomor Notice"];

  constructor(props) {
    super(props);

    const { match } = this.props;
    const code_svb = match.params.code_svb;

    this.initState = {
      codeSVB: code_svb,
      inputValueDealer: "",
      loadingButton: false,
      dataSheet: [
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
      ],
    };

    this.state = { ...this.initState };
  }

  componentDidMount = () => {
    this.onResetSheet();
  };

  changeInputDealer = (v) => {
    this.setState({
      inputValueDealer: v,
    });
  };

  onSheetChange = (newData) => {
    // if (newData !== this.state.dataSheet)
    this.setState({ dataSheet: newData });
  };

  onResetSheet = () => {
    this.setState({
      dataSheet: [
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
      ],
    });
  };

  onClickUploadData = async () => {
    const { codeSVB, inputValueDealer, dataSheet } = this.state;
    if (!inputValueDealer) {
      Modal.error({
        title: "Validation Error",
        content: "Dealer Not Valid",
      });
      return false;
    }

    const dataCleaned = dataSheet
      .map((row) => {
        if (row[0]?.value !== "" && row[0]?.value !== undefined) {
          return {
            vehicle_engine_number: row[0]?.value,
            vehicle_license_plate: row[1]?.value,
            vehicle_certificate_of_ownership_number: row[2]?.value,
            vehicle_registration_certificate_number: row[3]?.value,
            vehicle_notice_number: row[4]?.value,
          };
        } else {
          return null;
        }
      })
      .filter((data) => data !== null);

    if (dataCleaned.length > 0) {
      try {
        this.setState({
          loadingButton: true,
        });
        const params = {
          service_bureau_code: codeSVB,
          dealer_code: inputValueDealer,
          data_order: dataCleaned,
        };

        otodisService.setToken(this.token);
        const updateDoc = await otodisService.updateDocumentsOrderBBN(params);
        Modal.success({
          content: updateDoc.data.success.message,
        });
        this.onResetSheet();
      } catch (error) {
        this.setState({
          loadingButton: false,
        });
        Modal.error({
          title: "Process Error",
          content: error.message,
        });
      }
    } else {
      Modal.error({
        title: "Validation Error",
        content: "Data Table Kosong",
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <MainLayout defaultOpenKeys={["m-document"]} defaultSelectedKeys={["m-document-update"]}>
          <div style={{ padding: 10, margin: 10, background: "#FFF" }}>
            <Form name="form-input" layout="vertical">
              <Row>
                <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 3 }} lg={{ span: 4 }} md={{ span: 6 }} sm={{ span: 12 }}>
                  <SelectDealer value={this.state.inputValueDealer} onChangeValue={this.changeInputDealer} />
                </Col>
                <Col style={{ paddingLeft: 5, paddingRight: 5 }}>
                  <Form.Item label="&nbsp;">
                    <Button onClick={this.onClickUploadData} type="primary" icon={<UploadOutlined />} title="Upload Tagihan" style={{ background: "#1784b8", borderColor: "#1784b8" }} loading={this.state.loadingButton}>
                      Upload Data
                    </Button>
                  </Form.Item>
                </Col>
                <Col style={{ paddingLeft: 5, paddingRight: 5 }}>
                  <Form.Item label="&nbsp;">
                    <Button onClick={this.onResetSheet} type="primary" icon={<ReloadOutlined />} title="Upload Tagihan" style={{ background: "#ca2e2e", borderColor: "#ca2e2e" }} loading={this.state.loadingButton}>
                      Refresh Table
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col xl={{ span: 24 }}>
                <Spreadsheet data={this.state.dataSheet} columnLabels={this.columnLabels} onChange={this.onSheetChange} />
              </Col>
            </Row>
          </div>
        </MainLayout>
      </React.Fragment>
    );
  }
}

export default withAuth(DocumentsUpdateNumber);
