import axios from "axios";
import { baseUrlAuth, apikeybaseUrlAuth } from "../config/ApiUrlConfig";


class AuthServices {

  axios = axios.create({
    baseURL: baseUrlAuth,
    headers: { "X-API-KEY": apikeybaseUrlAuth },
    timeout: 30000
  });

  setToken(token) {
    this.axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }

  async login(postData) {
    try {

      postData.app_code = "BIROJASA";
      const config = {
        method: 'post',
        url: '/auth/login',
        headers: {
          'X-API-KEY': apikeybaseUrlAuth,
        },
        data: postData
      };

      return await this.axios(config);
    } catch (e) {
      throw new Error(e?.response?.data?.message);
    }
  }

  async myAccount(pageCode, projectCode) {
    pageCode = (!pageCode) ? 0 : pageCode;
    projectCode = (!projectCode) ? 0 : projectCode;

    try {
      return await this.axios.get(`/auth/check?app_code=BIROJASA&project_code=${projectCode}&page_code=${pageCode}`, {
        headers: {
          "X-Api-Key": "Uj7IujdaOe2RqiTzSjo8zaW9NN6es1uZ54BTHeqi",
        }
      });
    } catch (e) {
      // console.log(e)
      throw new Error(e);
    }
  }
}

export const authServices = new AuthServices();
