import React, { Component } from "react";
import Cookies from "universal-cookie";
import withAuth from "../../hoc/withAuth";
import { Link } from "react-router-dom";

import ButtonLogout from "../buttons/ButtonLogout";
import SelectBiro from "../selects/SelectBiro";

import { config } from "../../config/GeneralConfig";

import { Col, Layout, Menu } from "antd";

import { CopyOutlined, FileDoneOutlined, TagsOutlined, HomeOutlined } from "@ant-design/icons";

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

class MainLayout extends Component {
  cookies = new Cookies();

  constructor(props) {
    super(props);

    this.state = {
      username: "",
      activeCode: this.props.match.params.code_svb,
      listServiceBureau: {},
      repaymentMenu: "none",
      showJabar: true,
      showSulut: false,
    };
  }

  componentDidMount = () => {
    let currentState = { ...this.state };
    const token = this.cookies.get("_bbn_tn");

    if (token) {
      currentState.username = atob(this.cookies.get("_bbn_un"));
      currentState.listServiceBureau = JSON.parse(atob(this.cookies.get("_bbn_ls")));
    }

    if (config.allowed_menu_repay.includes(currentState.username)) {
      currentState.repaymentMenu = "block";
    }

    const { exceptionBBN } = config;
    if (exceptionBBN.includes(currentState.activeCode)) {
      currentState.showJabar = false;
      currentState.showSulut = true;
    } else {
      currentState.showJabar = true;
      currentState.showSulut = false;
    }

    this.setState(currentState);
  };

  componentDidUpdate(prevProps) {
    if (this.props.match.params.code_svb !== prevProps.match.params.code_svb) {
      const { exceptionBBN } = config;
      const newActiveCode = this.props.match.params.code_svb;

      // Update the states based on the new activeCode
      if (exceptionBBN.includes(newActiveCode)) {
        this.setState({
          showJabar: false,
          showSulut: true,
          activeCode: newActiveCode,
        });
      } else {
        this.setState({
          showJabar: true,
          showSulut: false,
          activeCode: newActiveCode,
        });
      }
    }
  }

  render() {
    const { username, activeCode } = this.state;
    const { defaultSelectedKeys, defaultOpenKeys, children } = this.props;

    if (config.maintenance) {
      return (
        <div>
          <center>
            <div>
              <h1 className="mt-5 mb-0 pb-0">We will Back Soon </h1>
              <p>We perfoming maintenance, Please check us again soon</p>
            </div>
          </center>
        </div>
      );
    } else {
      return (
        <Layout style={{ minHeight: "100vh" }}>
          <Header className="bg-primary-custom d-flex justify-content-between" style={{ height: "50px", lineHeight: "normal", color: "#FFF", position: "sticky", top: 0, zIndex: 1, width: "100%", alignItems: "center", padding: 5 }}>
            <Col xs={{ span: 0 }} lg={{ span: 4 }} style={{ minWidth: 100, marginLeft: 5 }}>
              <strong>PORTAL&nbsp;BIRO&nbsp;JASA</strong>
            </Col>
            <Col>
              <Col>
                <SelectBiro value={activeCode} listServiceBureau={this.state.listServiceBureau} />
              </Col>
            </Col>
            {/* <Col>&nbsp;</Col> */}
            <Col style={{ minWidth: 100 }}>
              <span>Hi,&nbsp;{username}</span>
              &nbsp;&nbsp;
              <ButtonLogout />
            </Col>
          </Header>
          <Layout>
            <Sider
              theme="light"
              breakpoint="lg"
              collapsedWidth="0"
              onBreakpoint={(broken) => {
                // console.log(broken);
              }}
              onCollapse={(collapsed, type) => {
                // console.log(collapsed, type);
              }}
            >
              <Menu defaultSelectedKeys={defaultSelectedKeys} defaultOpenKeys={defaultOpenKeys} mode="inline">
                <Menu.Item key="m-dashboard" icon={<HomeOutlined />}>
                  <Link to={`/` + activeCode + `/dashboard`}>Dashboard</Link>
                </Menu.Item>
                <SubMenu key="m-order" icon={<TagsOutlined />} title="Order">
                  <Menu.Item key="m-list-order">
                    <Link to={`/` + activeCode + `/order/list-order`}>List Order</Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu key="m-document" icon={<CopyOutlined />} title="Dokumen">
                  <Menu.Item key="m-document-upload">
                    <Link to={`/` + activeCode + `/document/upload`}>Upload Dokumen</Link>
                  </Menu.Item>
                  <Menu.Item key="m-document-update">
                    <Link to={`/` + activeCode + `/document/update/document-number`}>Update Nomor Dokumen</Link>
                  </Menu.Item>
                  {/* {showSulut === true && (
                    <Menu.Item key="m-upload-notice-sulawesi">
                      <Link to={`/${activeCode}/document/upload/notice/sulawesi`}>Upload Notice Sulawesi</Link>
                    </Menu.Item>
                  )}
                  {showJabar === true && ( */}
                  <Menu.Item key="m-upload-notice">
                    <Link to={`/${activeCode}/document/upload/notice`}>Upload Notice</Link>
                  </Menu.Item>
                  <Menu.Item key="m-upload-bpkb">
                    <Link to={`/${activeCode}/document/upload/bpkb`}>Upload BPKB</Link>
                  </Menu.Item>
                  {/* )} */}
                </SubMenu>
                <SubMenu key="m-invoice" icon={<FileDoneOutlined />} title="Tagihan">
                  <Menu.Item key="m-invoice-upload">
                    <Link to={`/` + activeCode + `/service-bureau/invoice-upload`}>Upload Tagihan</Link>
                  </Menu.Item>
                  {/* <Menu.Item key="m-invoice-pay">
                    <Link to={`/` + activeCode + `/service-bureau/invoice-payment`}>Bayar Tagihan</Link>
                  </Menu.Item> */}
                  {/* <Menu.Item key="m-invoice-repay" style={{ display: this.state.repaymentMenu }}>
                    <Link to={`/` + activeCode + `/service-bureau/invoice-repayment`}>Bayar Ulang Tagihan</Link>
                  </Menu.Item> */}
                  <Menu.Item key="m-invoice-upload-svb-cost">
                    <Link to={`/` + activeCode + `/service-bureau/invoice-service-cost/upload`}>Upload Tagihan Biaya Jasa</Link>
                  </Menu.Item>
                </SubMenu>

                <SubMenu key="m-payment" icon={<FileDoneOutlined />} title="Pembayaran">
                  <Menu.Item key="m-payment-notice">
                    <Link to={`/` + activeCode + `/service-bureau/payment/tax-samsat`}>Biaya Notice </Link>
                  </Menu.Item>
                  <Menu.Item key="m-payment-notice-retry" style={{ display: this.state.repaymentMenu }}>
                    <Link to={`/` + activeCode + `/service-bureau/payment/tax-samsat/retry`}>Bayar Ulang Notice </Link>
                  </Menu.Item>
                  <Menu.Item key="m-payment-process-cost">
                    <Link to={`/` + activeCode + `/service-bureau/payment/process-cost`}>Bayar Biaya Proses </Link>
                  </Menu.Item>
                  <Menu.Item key="m-payment-pkb-bill">
                    <Link to={`/` + activeCode + `/service-bureau/payment/pkb-bill`}>Bayar Tagihan PKB </Link>
                  </Menu.Item>
                </SubMenu>
              </Menu>
            </Sider>
            <Content>
              <div>{children}</div>
            </Content>
          </Layout>
        </Layout>
      );
    }
  }
}

export default withAuth(MainLayout);
