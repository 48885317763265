const config = {
  status_bbn: {
    INPUTED: "INPUTED",
    UPLOADED: "UPLOADED",
    GENERATED: "GENERATED",
    FINISHED: "FINISHED",
    CANCELED: "CANCELED",
  },
  status_bayar: {
    ALL: "ALL",
    LUNAS_NOTICE: "LUNAS NOTICE",
    LUNAS_PROSES: "LUNAS PROSES",
    BELUM_LUNAS_NOTICE: "BELUM LUNAS NOTICE",
    BELUM_LUNAS_PROSES: "BELUM LUNAS PROSES",
  },
  status_notice: {
    TRUE: "UPLOADED",
    FALSE: "NOT UPLOADED",
  },
  status_bayar_notice: {
    GENERATED: "LUNAS NOTICE & PROCESS",
    UPLOADED: "BELUM LUNAS NOTICE & PROCESS",
  },
  porta_biro_jasa_apikey: "hwfub9Mvj29SLFL9hrOIH7EedeUjnX4U1kckJiQW",
  limit_pay_invoice: 200000000,
  limit_pay_process: 25000000,
  allowed_menu_repay: ["rora biro"],
  version: "0.2.6",
  maintenance: false,
  option_target_bill: ["default", "customize"],
  exceptionBBN: ["AIMND", "AWMND", "AJGTO", "WJYGTO"],
  option_notice_type: ["JABAR", "JAKARTA", "MANADO", "GORONTALO"],
};

export { config };
