import React from "react";
import Cookies from "universal-cookie";
import moment from "moment";
import { CSVLink } from "react-csv";

import withAuth from "../hoc/withAuth";
import MainLayout from "../components/layouts/MainLayout";
import SelectDealer from "../components/selects/SelectDealer";
import SelectStatusBBN from "../components/selects/SelectStatusBBN";
import { otodisService } from "../services/OtodisService";

import { Form, DatePicker, Input, Table, Button, Modal, Row, Col, Space, Tooltip } from "antd";
import { SearchOutlined, ExportOutlined, CopyOutlined } from "@ant-design/icons";
import SelectStatusBayar from "../components/selects/SelectStatusBayar";
import SelectStatusNotice from "../components/selects/SelectStatusNotice";
import ModalDetailBBN from "../components/modal/ModalDetailBBN";

class ListOrder extends React.Component {
  token = new Cookies().get("_bbn_tn");
  tableColumn = [
    {
      title: "Kode Order",
      dataIndex: "order_code",
      width: 200,
      render: (value, row) => {
        return (
          <div>
            <span className={`font-90-link`} onClick={(event) => this.showDetail(row)}>
              {value}
            </span>
            &nbsp; &nbsp;
            <Tooltip title="Click untuk copy kode order" color={"cyan"}>
              <CopyOutlined className={"small-avatar-link"} onClick={(event) => this.copyToClipboard(value)} />
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Nama Pemesan",
      dataIndex: "customer_doc_name",
      key: "customer_doc_name",
      width: 120,
    },
    {
      title: "Tgl Pengugasan",
      dataIndex: "create_date",
      key: "create_date",
      width: 100,
    },

    {
      title: `No Mesin & No Rangka`,
      dataIndex: "vehicle_engine_number",
      key: "vehicle_engine_number",
      width: 100,
      render: (value, row) => {
        return (
          <React.Fragment>
            <span>
              <b>{`${row.vehicle_engine_number}`}</b>
            </span>
            <br />
            <span>{`${row.vehicle_chassis_number}`}</span>
          </React.Fragment>
        );
      },
    },
    {
      title: "Variant Kend",
      dataIndex: "vehicle_variant_name",
      key: "vehicle_variant_name",
      width: 120,
    },
    {
      title: "No Tagihan",
      dataIndex: "invoice_number",
      key: "invoice_number",
      width: 170,
      render: (value, row) => {
        return (
          <React.Fragment>
            <span>{`${row.invoice_number}`}</span>
            <br />
            <span>
              <b>{`${row.service_bureau_code}`}</b>
            </span>
          </React.Fragment>
        );
      },
    },

    {
      title: "Biaya Notice",
      dataIndex: "notice_cost",
      key: "notice_cost",
      width: 70,
    },
    {
      title: "Biaya Proses",
      dataIndex: "process_cost",
      key: "process_cost",
      width: 70,
    },
    {
      title: "Total BBN",
      dataIndex: "transfer_title_cost",
      key: "transfer_title_cost",
      width: 70,
    },
    {
      title: "Status",
      dataIndex: "transfer_title_status",
      key: "transfer_title_status",
      width: 70,
    },
    {
      title: "Payment Approved",
      dataIndex: "payment_approved",
      key: "payment_approved",
      width: 70,
    },
    {
      title: "Lunas Notice",
      dataIndex: "paid_notice_cost",
      key: "paid_notice_cost",
      width: 180,
      render: (value, row) => {
        return (
          <React.Fragment>
            <span>
              <b>{row.paid_notice_cost ? "SUDAH BAYAR" : "BELUM BAYAR"}</b>
            </span>
            <br />
            <span>Tgl Bayar : {`${row.paid_notice_cost_date}`}</span>
          </React.Fragment>
        );
      },
    },
    {
      title: "Lunas Proses",
      dataIndex: "paid_process_cost",
      key: "paid_process_cost",
      width: 180,
      render: (value, row) => {
        return (
          <React.Fragment>
            <span>
              <b>{row.paid_process_cost ? "SUDAH BAYAR" : "BELUM BAYAR"}</b>
            </span>
            <br />
            <span>Tgl Bayar : {`${row.paid_process_cost_date}`}</span>
          </React.Fragment>
        );
      },
    },
    {
      title: "Lunas PK",
      dataIndex: "flag_has_outstanding",
      key: "flag_has_outstanding",
      width: 70,
    },

    {
      title: "ID DDMS & Tgl DDMS Bast",
      dataIndex: "daya_bast_date",
      key: "daya_bast_date",
      width: 170,
      render: (value, row) => {
        return (
          <React.Fragment>
            <span>
              <b>{`${row.daya_id_order}`}</b>
            </span>
            <br />
            <span>{`${row.daya_bast_date}`}</span>
          </React.Fragment>
        );
      },
    },
    {
      title: "DDMS FAKTUR",
      dataIndex: "daya_invoice_stnk",
      key: "daya_invoice_stnk",
      width: 100,
    },
    {
      title: "No Polisi",
      dataIndex: "vehicle_license_plate",
      key: "vehicle_license_plate",
      width: 100,
    },
    {
      title: "STNK",
      dataIndex: "vehicle_registration_certificate_number",
      key: "vehicle_registration_certificate_number",
      width: 80,
    },
    {
      title: "Tgl STNK",
      dataIndex: "vehicle_registration_certificate_entry_date",
      key: "vehicle_registration_certificate_entry_date",
      width: 150,
      render: (value, row) => {
        return (
          <React.Fragment>
            <span>
              <b>IN</b> : {`${row.vehicle_registration_certificate_entry_date?.slice(0, -5)}`}
            </span>
            <br />
            <span>
              <b>OUT</b>: {`${row.vehicle_registration_certificate_submission_date?.slice(0, -5)}`}
            </span>
          </React.Fragment>
        );
      },
    },
    {
      title: "BPKB",
      dataIndex: "vehicle_certificate_of_ownership_number",
      key: "vehicle_certificate_of_ownership_number",
      width: 85,
    },
    {
      title: "Tgl BPKB",
      dataIndex: "vehicle_certificate_of_ownership_entry_date",
      key: "vehicle_certificate_of_ownership_entry_date",
      width: 150,
      render: (value, row) => {
        return (
          <React.Fragment>
            <span>
              <b>IN</b> : {`${row.vehicle_certificate_of_ownership_entry_date?.slice(0, -5)}`}
            </span>
            <br />
            <span>
              <b>OUT</b>: {`${row.vehicle_certificate_of_ownership_submission_date?.slice(0, -5)}`}
            </span>
          </React.Fragment>
        );
      },
    },
  ];

  generateFileCsv = React.createRef();
  constructor(props) {
    super(props);

    const { match } = this.props;
    const code_svb = match.params.code_svb;

    this.initState = {
      code_svb: code_svb,
      processing: false,
      processingFetchTableData: false,
      dataOrder: [],
      pageSize: 100,
      filterValueDealer: "",
      filterValueDate: "",
      filterValueStatus: "INPUTED",
      filterValueStatusBayar: "ALL",
      filterValueStatusNotice: "ALL",
      filterValueNosin: "",
      loadingButton: false,
      tableSelectedRowKeys: [],
      dataExport: [[["order_code", "nama_pemesan", "no_mesin", "no_rangka", "variant_kend", "no_tagihan", "total_bbn", "status", "no_polisi", "ddms_bast", "ddms_spk", "ddms_faktur", "lunas_pk", "stnk", "tgl_masuk_stnk", "tgl_keluar_stnk", "bpkb", "tgl_masuk_bpkb", "tgl_keluar_bpkb"]]],
      rowDetailOrder: [],
      showModalDetailOrder: false,
    };

    this.state = { ...this.initState };
  }

  closeModalDetailOrder = (data) => {
    this.setState({
      showModalDetailOrder: false,
    });
  };

  showDetail = (data) => {
    // console.log(data);
    this.setState({
      rowDetailOrder: data,
      showModalDetailOrder: true,
    });
  };

  changeFilterDealer = (v) => {
    this.setState({
      filterValueDealer: v,
    });
  };

  changeFilterDate = (date, dateString) => {
    this.setState({
      filterValueDate: dateString,
    });
  };

  changeFilterStatus = (v) => {
    this.setState({
      filterValueStatus: v,
    });
  };

  changeFilterValueStatusNotice = (v) => {
    this.setState({
      filterValueStatusNotice: v,
    });
  };

  changeFilterStatusBayar = (v) => {
    this.setState({
      filterValueStatusBayar: v,
    });
  };

  changeFilterNosin = (v) => {
    this.setState({
      filterValueNosin: v.target.value,
    });
  };

  fetchDataOrder = async () => {
    try {
      const filter = this.state;

      if (!filter.filterValueNosin) {
        if (!filter.filterValueStatus) {
          throw new Error("Status is Required");
        } else {
          // if (!filter.filterValueDealer) {
          //   throw new Error("Dealer is Required");
          // }
          if (!filter.filterValueDate && !filter.filterValueNosin && (filter.filterValueStatus === "GENERATED" || filter.filterValueStatus === "FINISHED")) {
            throw new Error("Date is Required");
          }
        }
      }

      this.setState({
        processingFetchTableData: true,
        loadingButton: true,
      });

      const params = {};

      params.service_bureau_code = this.state.code_svb;
      if (filter.filterValueNosin !== "") {
        params.vehicle_engine_number = filter.filterValueNosin;
      } else {
        if (filter.filterValueStatus !== "") {
          params.transfer_title_status = filter.filterValueStatus;
        }
        if (filter.filterValueDealer !== "") {
          params.dealer_code = filter.filterValueDealer;
        }

        if (filter.filterValueDate !== "") {
          params.date_start = moment(filter.filterValueDate).clone().startOf("month").format("YYYY-MM-DD");
          params.date_end = moment(filter.filterValueDate).clone().endOf("month").format("YYYY-MM-DD");
        }

        console.log(filter.filterValueStatusNotice);
        if (filter.filterValueStatusNotice !== "ALL") {
          params.notice_uploaded = filter.filterValueStatusNotice === "TRUE" ? true : false;
        }

        if (filter.filterValueStatusBayar !== "ALL") {
          switch (filter.filterValueStatusBayar) {
            case "LUNAS_NOTICE":
              params.paid_notice_cost = true;
              break;

            case "LUNAS_PROSES":
              params.paid_process_cost = true;
              break;

            case "BELUM_LUNAS_NOTICE":
              params.paid_notice_cost = false;
              break;

            case "BELUM_LUNAS_PROSES":
              params.paid_process_cost = false;
              break;

            default:
              break;
          }
        }
      }

      console.log("params", params);
      otodisService.setToken(this.token);
      const dataOrder = await otodisService.getAssignedOrder(params);
      // console.log(dataOrder.data.success.data);

      this.setState({
        dataOrder: dataOrder.data.success.data,
        processingFetchTableData: false,
        loadingButton: false,
        tableSelectedRowKeys: [],
      });
    } catch (error) {
      this.setState({
        dataOrder: [],
        processingFetchTableData: false,
        loadingButton: false,
        tableSelectedRowKeys: [],
      });
      Modal.error({
        title: "Validation Error",
        content: error.message,
      });
      return false;
    }
  };

  onClickFilter = async () => {
    await this.fetchDataOrder();
  };

  onClickExport = async () => {
    let dataGenerate = this.state.tableSelectedRowKeys;
    let dataParsed = [
      [
        "Order Code",
        "Nama Pemesan",
        "Tgl Pengugasan",
        "No Mesin & No Rangka",
        "Variant Kend",
        "No Tagihan",
        "Biaya Notice",
        "Biaya Proses",
        "Total BBN",
        "Status",
        "Lunas Notice",
        "Lunas Proses",
        "Lunas PK",
        "ID DDMS & Tgl DDMS Bast",
        "DDMS FAKTUR",
        "No Polisi",
        "STNK",
        "Tgl STNK",
        "BPKB",
        "Tgl BPKB",
      ],
    ];
    await dataGenerate.map((v) => {
      return dataParsed.push([
        v.order_code,
        v.customer_doc_name,
        v.create_date,
        v.vehicle_engine_number,
        v.vehicle_variant_name,
        v.invoice_number,
        v.notice_cost,
        v.process_cost,
        v.transfer_title_cost,
        v.transfer_title_status,
        v.paid_notice_cost,
        v.paid_process_cost,
        v.flag_has_outstanding,
        v.daya_bast_date,
        v.daya_invoice_stnk,
        v.vehicle_license_plate,
        v.vehicle_registration_certificate_number,
        v.vehicle_registration_certificate_entry_date,
        v.vehicle_certificate_of_ownership_number,
        v.vehicle_certificate_of_ownership_entry_date,
      ]);
    });

    if (dataParsed.length < 1) {
      Modal.error({
        title: "Validation Error",
        content: "Tidak Ada Data yang Dipilih",
      });
      return false;
    }

    this.setState({
      loadingButton: true,
    });

    try {
      await this.setState({
        dataExport: dataParsed,
      });

      this.generateFileCsv.current.link.click();
      this.setState({
        loadingButton: false,
      });
    } catch (error) {
      Modal.error({
        title: "Process Error",
        content: error.message,
      });
    }
  };

  render() {
    const rowTableSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          tableSelectedRowKeys: selectedRows,
        });
      },
    };
    return (
      <React.Fragment>
        <MainLayout defaultSelectedKeys={["m-list"]}>
          <div style={{ padding: 10, margin: 10, background: "#FFF" }}>
            <Form name="form-filter" layout="vertical" className="mx-3 mt-2">
              <Row>
                <Col style={{ paddingLeft: 2, paddingRight: 2, minWidth: 150 }}>
                  <SelectStatusBBN value={this.state.filterValueStatus} onChangeValue={this.changeFilterStatus} />
                </Col>
                <Col style={{ paddingLeft: 2, paddingRight: 2, minWidth: 150 }}>
                  <SelectDealer value={this.state.filterValueDealer} onChangeValue={this.changeFilterDealer} />
                </Col>
                <Col style={{ paddingLeft: 2, paddingRight: 2, minWidth: 150 }}>
                  <Form.Item label="Tanggal Order">
                    <DatePicker onChange={this.changeFilterDate} picker="month" />
                  </Form.Item>
                </Col>
                <Col style={{ paddingLeft: 2, paddingRight: 2, minWidth: 150 }}>
                  <SelectStatusNotice value={this.state.filterValueStatusNotice} onChangeValue={this.changeFilterValueStatusNotice} />
                </Col>
                <Col style={{ paddingLeft: 2, paddingRight: 2, minWidth: 150 }}>
                  <SelectStatusBayar value={this.state.filterValueStatusBayar} onChangeValue={this.changeFilterStatusBayar} />
                </Col>
                <Col style={{ paddingLeft: 2, paddingRight: 2, minWidth: 150 }}>
                  <Form.Item label="Nomor Mesin">
                    <Input onChange={this.changeFilterNosin} placeholder="Nomor Mesin" />
                  </Form.Item>
                </Col>
                <Col style={{ paddingLeft: 2, paddingRight: 2, minWidth: 150 }}>
                  <Form.Item label="&nbsp;">
                    <Space.Compact style={{ width: "100%" }}>
                      <Button onClick={this.onClickFilter} type="primary" icon={<SearchOutlined />} title="filter" style={{ minWidth: "50%", background: "#1784b8", borderColor: "#1784b8" }} loading={this.state.loadingButton}>
                        Filter
                      </Button>
                      <Button onClick={this.onClickExport} type="primary" icon={<ExportOutlined />} title="export excel" style={{ minWidth: "50%", background: "#17a2b8", borderColor: "#17a2b8" }} loading={this.state.loadingButton}>
                        Export
                      </Button>
                    </Space.Compact>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <CSVLink data={this.state.dataExport} filename="data-order.csv" ref={this.generateFileCsv} target="_blank" style={{ display: "none" }}>
              Export
            </CSVLink>
            <Table
              rowSelection={rowTableSelection}
              scroll={{ x: 2000 }}
              rowKey={"order_code"}
              size={"small"}
              loading={this.state.processingFetchTableData}
              columns={this.tableColumn}
              dataSource={this.state.dataOrder}
              pagination={{ pageSize: this.state.pageSize }}
              onChange={this.onChangeTable}
              className="text-uppercase"
              style={{ marginTop: 20, fontSize: "10%" }}
            />
          </div>
          <ModalDetailBBN kodeOrder={"aaaa"} dataOrder={this.state.rowDetailOrder} visible={this.state.showModalDetailOrder} onClose={this.closeModalDetailOrder} />
        </MainLayout>
      </React.Fragment>
    );
  }
}

export default withAuth(ListOrder);
