import React, { Component } from "react";
import moment from "moment";
import { Col, Image, Modal, Row, Tag } from "antd";

class ModalDetailBBN extends Component {
  constructor(props) {
    super(props);

    this.initState = {
      dataOrder: this.props.dataOrder ? this.props.dataOrder : {},
    };
    this.state = { ...this.initState };
  }

  copyToClipboard = async (data) => {
    await navigator.clipboard.writeText(data);
  };

  render() {
    const dataOrder = this.props.dataOrder;
    const calNoticeCost = Number(dataOrder?.vehicle_notice_data?.bbnkb) + Number(dataOrder?.vehicle_notice_data?.pkb) + Number(dataOrder?.vehicle_notice_data?.swdkllj) + Number(dataOrder?.vehicle_notice_data?.penerbitan_stnk) + Number(dataOrder?.vehicle_notice_data?.penerbitan_tnkb);

    const selisih_notice_dokumen_hitung = calNoticeCost - Number(dataOrder?.vehicle_notice_data?.jumlah);
    const selisih_notice_baru_lama = Number(dataOrder?.vehicle_notice_data?.jumlah) - Number(dataOrder.master_notice_cost);

    const total_bbn_lama = Number(dataOrder.master_notice_cost) + Number(dataOrder.process_cost);
    const total_bbn_baru = Number(dataOrder?.vehicle_notice_data?.jumlah) + Number(dataOrder.process_cost);
    const selisih_bbn = total_bbn_baru - total_bbn_lama;
    const total_bbn_tercatat = Number(dataOrder.transfer_title_cost);

    return (
      <Modal title="Detail Order" visible={this.props.visible} onCancel={this.props.onClose} footer={null} width={900} style={{ maxHeight: "80vh" }}>
        {dataOrder && (
          <div style={{ maxHeight: "calc(80vh - 55px)", overflowY: "auto" }}>
            <Row style={{ marginTop: 20 }}>
              <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Kode Order</Col>
                  <Col span={16}>:&nbsp;{dataOrder.order_code}</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Nama Konsumen</Col>
                  <Col span={16}>:&nbsp;{dataOrder.customer_doc_name}</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Lunas PK & PP</Col>
                  <Col span={16}>
                    :&nbsp;
                    {dataOrder.flag_has_outstanding === "TRUE" ? <Tag color="#40a9ff">{dataOrder.flag_has_outstanding}</Tag> : <Tag color="#faad14">{dataOrder.flag_has_outstanding}</Tag>}
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Kode Samsat</Col>
                  <Col span={16}>:&nbsp;{dataOrder.samsat_code}</Col>
                </Row>
              </Col>
              <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Biro Jasa</Col>
                  <Col span={16}>
                    :&nbsp;{dataOrder.service_bureau_code} - {dataOrder.service_bureau_name}
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>No Rekening Biro</Col>
                  <Col span={16}>:&nbsp;{dataOrder.service_bureau_account_number}</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Status BBN</Col>
                  <Col span={16}>
                    :&nbsp;<strong>{dataOrder.transfer_title_status}</strong>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>No Tagihan BBN</Col>
                  <Col span={16}>:&nbsp;{dataOrder.invoice_number}</Col>{" "}
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={8}>BBN Kosong</Col>
                  <Col span={16}>:&nbsp;{dataOrder.transfer_title_null ? dataOrder.transfer_title_null : "FALSE"}</Col>
                </Row>
              </Col>
            </Row>

            <Row style={{ marginTop: 20 }}>
              <br />
              <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Nomor Mesin</Col>
                  <Col span={16}>:&nbsp;{dataOrder.vehicle_engine_number}</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>No Polisi</Col>
                  <Col span={16}>:&nbsp;{dataOrder.vehicle_license_plate}</Col>
                </Row>
              </Col>
              <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Nomor Rangka</Col>
                  <Col span={16}>:&nbsp;{dataOrder.vehicle_chassis_number}</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Variant Kendaraan</Col>
                  <Col span={16}>:&nbsp;{dataOrder.vehicle_variant_name}</Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                <br />
                <Row gutter={[16, 16]}>
                  <Col span={8}>Tgl Closing</Col>
                  <Col span={16}>:&nbsp;{dataOrder.order_closing_date}</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Tgl Penugasan</Col>
                  <Col span={16}>:&nbsp;{dataOrder.create_date}</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Tgl Upload Tagihan</Col>
                  <Col span={16}>:&nbsp;{dataOrder.transfer_title_date}</Col>
                </Row>
              </Col>
              <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                <br />
                <Row gutter={[16, 16]}>
                  <Col span={8}>Tgl Upload Notice</Col>
                  <Col span={16}>:&nbsp;{dataOrder.vehicle_notice_entry_date}</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Tgl Bayar Notice </Col>
                  <Col span={16}>:&nbsp;{dataOrder.paid_notice_cost_date}</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Tgl Bayar Proses</Col>
                  <Col span={16}>:&nbsp;{dataOrder.paid_process_cost_date}</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Sudah Bayar PKB</Col>
                  <Col span={16}>:&nbsp;{dataOrder?.paid_bill_pkb?.toString()?.toUpperCase()}</Col>
                </Row>
              </Col>
            </Row>

            <Row style={{ marginTop: 20 }}>
              <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                <Row gutter={[16, 16]}>
                  <br />
                  <Col span={24}>
                    <strong>Data Notice</strong>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={8}>No Notice</Col>
                  <Col span={16}>:&nbsp;{dataOrder?.vehicle_notice_data?.no_notice}</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Nama Pemilik</Col>
                  <Col span={16}>:&nbsp;{dataOrder?.vehicle_notice_data?.customer_name}</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Alamat</Col>
                  <Col span={16}>:&nbsp;{dataOrder?.vehicle_notice_data?.customer_address}</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>BPKB</Col>
                  <Col span={16}>:&nbsp;{dataOrder?.vehicle_notice_data?.bpkb_number}</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Nomor Polisi</Col>
                  <Col span={16}>:&nbsp;{dataOrder?.vehicle_notice_data?.license_plate_number}</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Berlaku S/D</Col>
                  <Col span={16}>:&nbsp;{dataOrder?.vehicle_notice_data?.expire_at}</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Kepemilikan Ke</Col>
                  <Col span={16}>:&nbsp;{dataOrder?.vehicle_notice_data?.ownership_order}</Col>
                </Row>
                <Row gutter={[16, 16]} style={{ fontWeight: "bold" }}>
                  <Col span={8}>Alasan Edit</Col>
                  <Col span={16}>:&nbsp;{dataOrder?.vehicle_notice_data?.description}</Col>
                </Row>
              </Col>

              <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                <br />
                <Row gutter={[16, 16]}>
                  <Col span={10}>Biaya BBNKB</Col>
                  <Col span={14}>
                    :&nbsp;<strong style={{ fontSize: "90%" }}>{dataOrder?.vehicle_notice_data?.bbnkb?.toLocaleString()}</strong>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={10}>Biaya PKB</Col>
                  <Col span={14}>
                    :&nbsp;<strong style={{ fontSize: "90%" }}>{dataOrder?.vehicle_notice_data?.pkb?.toLocaleString()}</strong>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={10}>Biaya SWDKLLJ</Col>
                  <Col span={14}>
                    :&nbsp;<strong style={{ fontSize: "90%" }}>{dataOrder?.vehicle_notice_data?.swdkllj?.toLocaleString()}</strong>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={10}>Biaya Penerbitan STNK</Col>
                  <Col span={14}>
                    :&nbsp;<strong style={{ fontSize: "90%" }}>{dataOrder?.vehicle_notice_data?.penerbitan_stnk?.toLocaleString()}</strong>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={10}>Biaya Penerbitan TNKB</Col>
                  <Col span={14}>
                    :&nbsp;<strong style={{ fontSize: "90%" }}>{dataOrder?.vehicle_notice_data?.penerbitan_tnkb?.toLocaleString()}</strong>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={10}>Biaya Notice Dokumen</Col>
                  <Col span={14}>
                    :&nbsp;<strong style={{ fontSize: "90%" }}>{dataOrder?.vehicle_notice_data?.jumlah?.toLocaleString()}</strong>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={10}>Biaya Notice Hitung</Col>
                  <Col span={14}>
                    :&nbsp;<strong style={{ fontSize: "90%" }}>{calNoticeCost?.toLocaleString()}</strong>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={10}>Selisih Notice Dokumen & Hitung</Col>
                  <Col span={14}>
                    :&nbsp;<strong style={{ fontSize: "90%", color: selisih_notice_dokumen_hitung !== 0 ? "red" : "inherit" }}>{selisih_notice_dokumen_hitung?.toLocaleString()}</strong>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                <br />

                <Row gutter={[16, 16]}>
                  <Col span={10}>Biaya Proses</Col>
                  <Col span={14}>
                    :&nbsp;<strong style={{ fontSize: "90%" }}>{dataOrder.process_cost?.toLocaleString()}</strong>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={10}>Biaya Notice Lama</Col>
                  <Col span={14}>
                    :&nbsp;<strong style={{ fontSize: "90%" }}>{dataOrder.master_notice_cost?.toLocaleString()}</strong>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={10}>Biaya Notice Baru</Col>
                  <Col span={14}>
                    :&nbsp;<strong style={{ fontSize: "90%" }}>{dataOrder?.vehicle_notice_data?.jumlah?.toLocaleString()}</strong>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={10}>Selisih Notice Lama & baru </Col>
                  <Col span={14}>
                    :&nbsp;<strong style={{ fontSize: "90%", color: selisih_notice_baru_lama !== 0 ? "red" : "inherit" }}>{selisih_notice_baru_lama?.toLocaleString()}</strong>
                  </Col>
                </Row>
              </Col>
              <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                <br />
                <Row gutter={[16, 16]}>
                  <Col span={10}>Biaya BBN Lama</Col>
                  <Col span={14}>
                    :&nbsp;<strong style={{ fontSize: "90%" }}>{total_bbn_lama?.toLocaleString()}</strong>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={10}>Biaya BBN Baru</Col>
                  <Col span={14}>
                    :&nbsp;<strong style={{ fontSize: "90%" }}>{total_bbn_baru?.toLocaleString()}</strong>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={10}>Selisih BBN Baru & Lama</Col>
                  <Col span={14}>
                    :&nbsp;<strong style={{ fontSize: "90%", color: selisih_bbn !== 0 ? "red" : "inherit" }}>{selisih_bbn?.toLocaleString()}</strong>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={10}>Biaya BBN Tercatat di DB</Col>
                  <Col span={14}>
                    :&nbsp;<strong style={{ fontSize: "90%" }}>{total_bbn_tercatat?.toLocaleString()}</strong>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                <Row gutter={[16, 16]}>
                  <br />
                  <Col span={24}>
                    <strong>Data Main Dealer</strong>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Penerima</Col>
                  <Col span={16}>:&nbsp;{dataOrder.daya_receiver_name}</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>ID Order</Col>
                  <Col span={16}>:&nbsp;{dataOrder.daya_id_order}</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Tanggal BAST</Col>
                  <Col span={16}>:&nbsp;{dataOrder.daya_bast_date && dataOrder.daya_bast_date !== "NONE" ? moment(dataOrder.daya_bast_date).format("YYYY-MM-DD HH:mm") : "-"}</Col>
                </Row>
              </Col>
              <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                <br />
                <Row gutter={[16, 16]}>
                  <Col span={8}>Nomor Invoice</Col>
                  <Col span={16}>:&nbsp;{dataOrder.daya_invoice_stnk}</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Waktu Input Invoice</Col>
                  <Col span={16}>:&nbsp;{dataOrder.daya_input_time && dataOrder.daya_input_time !== "NONE" ? moment(dataOrder.daya_input_time).format("YYYY-MM-DD HH:mm") : "-"}</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Tanggal Invoice</Col>
                  <Col span={16}>:&nbsp;{dataOrder.daya_invoice_date && dataOrder.daya_invoice_date !== "NONE" ? moment(dataOrder.daya_invoice_date).format("YYYY-MM-DD HH:mm") : "-"}</Col>
                </Row>
              </Col>
            </Row>

            <Row style={{ marginTop: 20 }}>
              <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                <Row gutter={[16, 16]}>
                  <br />
                  <Col span={24}>
                    <strong>Data Dokumen</strong>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={8}>No Notice</Col>
                  <Col span={16}>:&nbsp;{dataOrder.vehicle_notice_number}</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Tgl Masuk</Col>
                  <Col span={16}>:&nbsp;{dataOrder.vehicle_notice_entry_date}</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Tgl Keluar</Col>
                  <Col span={16}>:&nbsp;{dataOrder.vehicle_notice_submission_date}</Col>
                </Row>
              </Col>
              <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                <br />
                <Row gutter={[16, 16]}>
                  <Col span={8}>No BPKB</Col>
                  <Col span={16}>:&nbsp;{dataOrder.vehicle_certificate_of_ownership_number}</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Tgl Masuk</Col>
                  <Col span={16}>:&nbsp;{dataOrder.vehicle_certificate_of_ownership_entry_date}</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Tgl Keluar</Col>
                  <Col span={16}>:&nbsp;{dataOrder.vehicle_certificate_of_ownership_submission_date}</Col>
                </Row>
              </Col>
              <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                <br />
                <Row gutter={[16, 16]}>
                  <Col span={8}>No STNK</Col>
                  <Col span={16}>:&nbsp;{dataOrder.vehicle_registration_certificate_number}</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Tgl Masuk</Col>
                  <Col span={16}>:&nbsp;{dataOrder.vehicle_registration_certificate_entry_date}</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>Tgl Keluar</Col>
                  <Col span={16}>:&nbsp;{dataOrder.vehicle_registration_certificate_submission_date}</Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col style={{ padding: 10 }}>
                <br />
                <Image width={"inherit"} src={dataOrder?.vehicle_notice_image} style={{ alignContent: "center" }} />
              </Col>
            </Row>
          </div>
        )}
      </Modal>
    );
  }
}

export default ModalDetailBBN;
