import React, { Component } from "react";
import { withRouter } from "../../hoc/withRouter";
import { config } from "../../config/GeneralConfig";
import { Form, Select } from "antd";

const { Option } = Select;

class SelectStatusBBN extends Component {
  render() {
    const { status_bbn: status_order } = config;
    return (
      <Form.Item label="Status BBN" name="status_order" className="col-lg-12">
        <Select style={{ fontSize: "85%" }} placeholder="Select Status" defaultValue={this.props.value} onChange={this.props.onChangeValue}>
          {Object.keys(status_order).map((s) => {
            return (
              <Option key={s} value={s}>
                {status_order[s]}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  }
}

export default withRouter(SelectStatusBBN);
