import axios from "axios";

class MiscServices {

  axios = axios.create({
    timeout: 30000
  });


  async getTaxInvoiceData(url) {
    try {
      const config = {
        method: 'POST',
        url: "https://asia-southeast2-autotrimitra.cloudfunctions.net/otodis__taxActivityGCF/misc/bridging/tax-link",
        json: true,
        data: {
          link_tax_invoice: url
        }
      }
      return await this.axios(config);
    } catch (e) {
      return e.response.data;
    }
  }
}

export const miscservices = new MiscServices();
