import React, { useState } from "react";
import { Table, Transfer, Image } from "antd";
import difference from "lodash/difference";

const TransferBanyarNotice = ({ showModalFormVehicle, ...restProps }) => {
  const [visible, setVisible] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [scaleStep] = useState(0.5);

  const showModal = (image) => {
    setModalImage(image);
    setVisible(true);
  };

  const columnsLeft = [
    {
      title: "Kode Order",
      dataIndex: "key",
      width: 200,
      render: (text, row) => (
        <span style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }} onClick={() => showModal(row.vehicle_notice_image)}>
          {text}
        </span>
      ),
    },
    {
      title: "Deskripsi",
      dataIndex: "description",
      width: 150,
      render: (text) => <div dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, "<br/>") }} />,
    },
    {
      title: "Biaya Notice Master",
      dataIndex: "master_notice_cost",
      width: 80,
      render: (value, row) => {
        // console.log(value);
        const diff = Math.abs(Number(row.master_notice_cost) - Number(row.samsat_notice_cost));
        const color = diff !== 0 ? "#fd5e1e" : "black";
        return <span style={{ color: color }}>{`${value.toLocaleString()}`}</span>;
      },
    },
    {
      title: "Biaya Notice",
      dataIndex: "samsat_notice_cost",
      width: 80,
      render: (value, row) => {
        const diff = Math.abs(Number(row.master_notice_cost) - Number(row.samsat_notice_cost));
        const color = diff !== 0 ? "#fd5e1e" : "black";
        return <span style={{ color: color }}>{`${value.toLocaleString()}`}</span>;
      },
    },
    {
      title: "Biaya PKB",
      dataIndex: "samsat_pkb_cost",
      render: (value) => value.toLocaleString(),
      width: 80,
    },
    {
      title: "Total Bayar",
      dataIndex: "total_payment",
      render: (value) => value.toLocaleString(),
      width: 80,
    },
    {
      title: "Tgl Upload Tagihan",
      dataIndex: "upload_bbn_date",
      width: 80,
    },

    {
      title: "Tgl Upload Notice",
      dataIndex: "upload_notice_date",
      width: 80,
    },
  ];

  const columnsRight = [
    {
      title: "Kode Order",
      dataIndex: "key",
      width: 200,
      render: (text, row) => (
        <span style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }} onClick={() => showModal(row.image_notice)}>
          {text}
        </span>
      ),
    },
    {
      title: "Deskripsi",
      dataIndex: "description",
      width: 200,
      render: (text) => <div dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, "<br/>") }} />,
    },
    {
      title: "Biaya Notice Master",
      dataIndex: "master_notice_cost",
      width: 80,
      render: (value, row) => {
        const diff = Math.abs(Number(row.master_notice_cost) - Number(row.samsat_notice_cost));
        const color = diff !== 0 ? "#fd5e1e" : "black";
        return <span style={{ color: color }}>{`${value.toLocaleString()}`}</span>;
      },
    },
    {
      title: "Biaya Notice",
      dataIndex: "samsat_notice_cost",
      width: 80,
      render: (value, row) => {
        const diff = Math.abs(Number(row.master_notice_cost) - Number(row.samsat_notice_cost));
        const color = diff !== 0 ? "#fd5e1e" : "black";
        return <span style={{ color: color }}>{`${value.toLocaleString()}`}</span>;
      },
    },
    {
      title: "Biaya PKB",
      dataIndex: "samsat_pkb_cost",
      render: (value) => value.toLocaleString(),
      width: 80,
    },
    {
      title: "Total Bayar",
      dataIndex: "total_payment",
      render: (value) => value.toLocaleString(),
      width: 80,
    },
  ];

  return (
    <>
      <Transfer {...restProps}>
        {({ direction, filteredItems, onItemSelectAll, onItemSelect, selectedKeys: listSelectedKeys, disabled: listDisabled }) => {
          const columns = direction === "left" ? columnsLeft : columnsRight;

          const rowSelection = {
            getCheckboxProps: (item) => ({ disabled: listDisabled || item.disabled }),
            onSelectAll(selected, selectedRows) {
              const treeSelectedKeys = selectedRows.filter((item) => !item.disabled).map(({ key }) => key);
              const diffKeys = selected ? difference(treeSelectedKeys, listSelectedKeys) : difference(listSelectedKeys, treeSelectedKeys);
              onItemSelectAll(diffKeys, selected);
            },
            onSelect({ key }, selected) {
              onItemSelect(key, selected);
            },
            selectedRowKeys: listSelectedKeys,
          };

          return (
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={filteredItems}
              size="small"
              pagination={false}
              scroll={{ y: 300 }}
              style={{ pointerEvents: listDisabled ? "none" : undefined }}
              onRow={({ key, disabled: itemDisabled }) => ({
                onClick: () => {
                  if (itemDisabled || listDisabled) return;
                  onItemSelect(key, !listSelectedKeys.includes(key));
                },
              })}
            />
          );
        }}
      </Transfer>

      <Image
        width={200}
        style={{
          display: "none",
        }}
        src={modalImage}
        alt="Notice"
        preview={{
          visible,
          scaleStep,
          src: modalImage,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
    </>
  );
};

export default TransferBanyarNotice;
