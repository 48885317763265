import axios from "axios";
import { baseUrlOtodisUploadAPI } from "../config/ApiUrlConfig";

class UploadDocumentService {
  axios = axios.create({
    baseURL: baseUrlOtodisUploadAPI,
  });


  async upload(formData) {
    try {
      return await this.axios.post("/upload/document/service-bureau/order", formData);
    } catch (e) {
      const { response } = e;
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error.message);
    }
  }

  async uploadBpkb(formData) {
    try {
      return await this.axios.post("/extract/document/bpkb", formData);
    } catch (e) {
      const { response } = e;
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error.message);
    }
  }

  async extractNoticeJabar(formData) {
    try {
      return await this.axios.post("/extract/document/notice", formData);
    } catch (e) {
      const { response } = e;
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error.message);
    }
  }

  async extractNoticeSulawesi(formData) {
    try {
      return await this.axios.post("/extract/document/notice/sulawesi", formData);
    } catch (e) {
      const { response } = e;
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error.message);
    }
  }

  async uploadNotice(formData) {
    try {
      return await this.axios.post("/upload/document/service-bureau/order/notice", formData);
    } catch (e) {
      const { response } = e;
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error.message);
    }
  }
}

export const uploadDocumentService = new UploadDocumentService();
