const companyCode = "amarta";

/**
 * PRODUCTION LINK.
 */
const apikeybaseUrlAuth = "Uj7IujdaOe2RqiTzSjo8zaW9NN6es1uZ54BTHeqi";

const baseUrlAuth = "https://01z4tn3758.execute-api.ap-southeast-1.amazonaws.com/v1/";
const baseUrlTrimitraIAM = "https://2rtwh7ezy0.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseUrlOtodisLambdaAPI = "https://v0b1z99035.execute-api.ap-southeast-1.amazonaws.com/v1/";
const baseUrlOtodisCloudFuncAPI = "https://asia-southeast2-autotrimitra.cloudfunctions.net/otodis__final_sales_BQ";
const baseUrlPortalBiroAPI = "https://2rtwh7ezy0.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseUrlOtodisUploadAPI = "https://35tt91zxme.execute-api.ap-southeast-1.amazonaws.com/otodis";
/**
 * DEVELOPMENT LINK.
 */
// const baseUrlAuth = "http://localhost:3001";
// const baseUrlTrimitraIAM = "http://localhost:3001";
// const baseUrlOtodisLambdaAPI = "http://localhost:3001";
// const baseUrlOtodisCloudFuncAPI = "http://localhost:3001";
// const baseUrlOtodisUploadAPI = "http://localhost:3001";
// const baseUrlPortalBiroAPI = "http://localhost:3001";

export { companyCode, baseUrlAuth, apikeybaseUrlAuth, baseUrlTrimitraIAM, baseUrlOtodisLambdaAPI, baseUrlOtodisCloudFuncAPI, baseUrlOtodisUploadAPI, baseUrlPortalBiroAPI };
